<template>
    <div class="container">
        <div class="ft-block">
            <div class="feature">
                <p class='ft-number'>370+</p>
                <p class='ft-script'>учеников</p>
                <p>уже сделали <br> свою первую куклу</p>
            </div>
            <div class="feature">
                <p class='ft-number'>1050</p>
                <p class='ft-script'>фото</p>
                <p>покажут весь процесс создания куклы наглядно</p>
            </div>
            <div class="feature">
                <p class='ft-number'>30</p>
                <p class='ft-script'>дней</p>
                <p>необходимо, чтобы создать свою первую куклу </p>
            </div>
        </div>
    </div>
</template>

<style>
    .ft-block{
        display: flex;
        margin: 100px 0;

        align-items: flex-start;
        justify-content: space-between;
        text-align: center;
        line-height: 15pt;
    }

    .feature {
        width: 25%;
    }

    .ft-number {
        font-size: 50pt;
        font-style: italic;
        line-height: 30pt;
    }

    .ft-script {
        font-size: 40pt;
        font-family: 'TheArtist';
        color: #FF7F80;
        position:relative;
        right: -50px;
        line-height: 30pt;
    }
</style>